<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} | TWUnlock` : `TWUnlock` }}
    </template>
  </metainfo>
  <router-view />
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "TWUnlock"
    });
  }
};
</script>
